import React, { useState } from "react";
import {
    Button,
    Col,
    /*DatePicker,*/
    FlexboxGrid,
    Form,
    Grid,
    Row,
    /*SelectPicker,*/
    Steps
} from "rsuite";
import { IconSelect } from "../elements/IconSelect";
import axios from "axios";
import Input from "rsuite/Input";

// eslint-disable-next-line react/display-name
const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

const Icons = [
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e66ff9635fa833009f_browser.svg",
        text: "Developing"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e7470f1a80207a48fd_bar-chart.svg",
        text: "Marketing"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e9470f1aea9b7a492d_shopping-bag.svg",
        text: "E-commerce"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e9808eb0efa6d5d614_user-4.svg",
        text: "Data science"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e7808eb085a8d5d613_laptop-2.svg",
        text: "Design"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e76cfbcc670e2ae0b7_security.svg",
        text: "Blockchain"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e72d167b02a1c64103_directions.svg",
        text: "Innovator"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e96d015776f586e468_transfer-1.svg",
        text: "3D Modeling"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e661232a45eb88b222_desk.svg",
        text: "Gamedesign"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e7470f1a9c737a48fc_invoice.svg",
        text: "Text Writing"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e92b329f1b2b13b459_startup.svg",
        text: "Video Editing"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e72b329f9f8213b457_question-mark.svg",
        text: "Something Else"
    }
];

const Options = [
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e661232a45eb88b222_desk.svg",
        text: "Rent a desk"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/6148821a652cd06e96ea2423_community.svg",
        text: "Join Community"
    }
];

const HauserForm = () => {
    const [step, setStep] = useState(0);
    const [option, setOption] = useState(0);
    const [selected, setSelected] = useState({});
    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
    };

    const [ambition, setAmbition] = useState("");
    const [bio, setBio] = useState("");
    const [why, setWhy] = useState("");
    const [company, setCompany] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [success, setSuccess] = useState(false);

    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);

    const onSend = async () => {
        try {
            await axios.post(
                "https://crm.the.haus/api/v1/LeadCapture/2f4b0a2734df02bbbbe449c440a08b1b",
                {
                    emailAddress: email,
                    title: "Dedicated Desk - Request",
                    website: company,
                    phoneNumber: telephone,
                    description: `Type: ${Options?.[option]?.text}\n\nProfession:\n ${Object.keys(selected)
                        .filter(key => selected[key])
                        .map(key => Icons[key].text)
                        .join(", ")}\n\nBio:\n${bio} \n\nAmbition:\n${ambition} \n\n Why:\n${why}`,
                    firstName: firstName,
                    lastName: lastName,
                    opportunityAmountCurrency: "EUR",
                    opportunityAmount: 220,
                    assignedUserId: "605c93587595740be"
                },
                {
                    headers: {
                        // Overwrite Axios's automatically set Content-Type
                        "Content-Type": "application/json"
                    }
                }
            );
        } catch (e) {
            console.error(e);
            return false;
        }
        setSuccess(true);
    };

    return success ? (
        <h2>
            Thank you for your request!
            <br />
            We will get back to you as soon as possible.
        </h2>
    ) : (
        <div>
            <Steps current={step} style={{ marginBottom: 60, flexWrap: "wrap" }}>
                <Steps.Item title="Choose" />
                <Steps.Item title="Profession" />
                <Steps.Item title="About you" />
                <Steps.Item title="Contact Info" />
            </Steps>
            <div
                style={
                    step !== 0
                        ? {
                              display: "none"
                          }
                        : {}
                }
            >
                <FlexboxGrid justify="space-around">
                    {Options.map(({ icon, text }, key) => (
                        <FlexboxGrid.Item as={Col} colspan={24} xs={12} md={6} key={key}>
                            <IconSelect
                                onClick={() => setOption(key)}
                                icon={icon}
                                selected={option === key}
                                text={text}
                            />
                        </FlexboxGrid.Item>
                    ))}
                </FlexboxGrid>
            </div>
            <div
                style={
                    step !== 1
                        ? {
                              display: "none"
                          }
                        : {}
                }
            >
                <FlexboxGrid justify="space-around">
                    {Icons.map(({ icon, text }, key) => (
                        <FlexboxGrid.Item as={Col} colspan={24} xs={12} md={6} key={key}>
                            <IconSelect
                                onClick={() =>
                                    setSelected({
                                        ...selected,
                                        [key]: !selected?.[key]
                                    })
                                }
                                icon={icon}
                                selected={!!selected?.[key]}
                                text={text}
                            />
                        </FlexboxGrid.Item>
                    ))}
                </FlexboxGrid>
            </div>
            <Form
                fluid
                style={
                    step !== 2
                        ? {
                              display: "none"
                          }
                        : {}
                }
            >
                <Form.Group>
                    <Form.ControlLabel>Tell us something about yourself!</Form.ControlLabel>
                    <Form.Control
                        value={bio}
                        onChange={value => setBio(value)}
                        rows={6}
                        style={{ width: "100%" }}
                        name="textarea"
                        accepter={Textarea}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel>What are your short term and long term goals?</Form.ControlLabel>
                    <Form.Control
                        value={ambition}
                        onChange={value => setAmbition(value)}
                        rows={6}
                        style={{ width: "100%" }}
                        name="textarea"
                        accepter={Textarea}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel>Why would you like to join The Haus?</Form.ControlLabel>
                    <Form.Control
                        value={why}
                        onChange={value => setWhy(value)}
                        rows={6}
                        style={{ width: "100%" }}
                        name="textarea"
                        accepter={Textarea}
                    />
                </Form.Group>
            </Form>
            <Grid style={{ display: step === 3 ? "block" : "none", margin: "0 auto", maxWidth: 500 }}>
                <Form fluid>
                    <Row>
                        <Col xs={24} style={{ marginBottom: 20 }}>
                            <Form.Group>
                                <Form.ControlLabel>Company:</Form.ControlLabel>
                                <Form.Control
                                    value={company}
                                    onChange={value => setCompany(value)}
                                    name="Company name"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.ControlLabel>Firstname</Form.ControlLabel>
                                <Form.Control
                                    value={firstName}
                                    onChange={value => setFirstName(value)}
                                    name="Voornaam"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Telephone</Form.ControlLabel>
                                <Form.Control value={telephone} onChange={value => setTelephone(value)} name="Phone" />
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.ControlLabel>Lastname</Form.ControlLabel>
                                <Form.Control value={lastName} onChange={value => setLastName(value)} name="Lastname" />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Email</Form.ControlLabel>
                                <Form.Control
                                    value={email}
                                    onChange={value => setEmail(value)}
                                    name="Email"
                                    type="email"
                                />
                            </Form.Group>
                        </Col>
                        {/*<Col xs={24} style={{ marginBottom: 20, marginTop: 20 }}>
                            <Form.Group>
                                <Form.Form.ControlLabel>Plan a tour</Form.Form.ControlLabel>
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                    placeholder="Plan a tour"
                                    block
                                    ranges={[
                                        {
                                            label: "Now",
                                            value: new Date()
                                        }
                                    ]}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={24} style={{ marginBottom: 20 }}>
                            <Form.Group>
                                <Form.Form.ControlLabel>For how long would you like to rent a desk:</Form.Form.ControlLabel>
                                <SelectPicker
                                    searchable={false}
                                    data={[
                                        {
                                            label: "1 month",
                                            value: "1 month"
                                        },
                                        {
                                            label: "2 months",
                                            value: "2 months"
                                        },
                                        {
                                            label: "3 months",
                                            value: "3 months"
                                        },
                                        {
                                            label: "More than 3 months",
                                            value: "More"
                                        }
                                    ]}
                                    block
                                />
                            </Form.Group>
                        </Col>*/}
                    </Row>
                </Form>
            </Grid>
            <FlexboxGrid justify="center" style={{ marginTop: 30 }}>
                <Button
                    onClick={onPrevious}
                    size={"lg"}
                    style={{ margin: "10px 10px" }}
                    disabled={step === 0}
                    color="red"
                    appearance="primary"
                >
                    Previous
                </Button>
                {step !== 3 ? (
                    <Button
                        onClick={onNext}
                        size={"lg"}
                        style={{ margin: "10px 10px" }}
                        disabled={step === 3}
                        color="red"
                        appearance="primary"
                    >
                        Next
                    </Button>
                ) : (
                    <Button
                        onClick={onSend}
                        color="green"
                        appearance="primary"
                        size={"lg"}
                        style={{ margin: "10px 10px" }}
                    >
                        Apply now
                    </Button>
                )}
            </FlexboxGrid>
        </div>
    );
};
export default HauserForm;
