import React from "react";
import { Col, DatePicker, Form, Row } from "rsuite";

const PersonForm = () => {
    return (
        <Form fluid>
            <Row>
                <Col xs={12}>
                    <Form.Group>
                        <Form.ControlLabel>Firstname</Form.ControlLabel>
                        <Form.Control name="Voornaam" />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Phone</Form.ControlLabel>
                        <Form.Control name="Phone" />
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group>
                        <Form.ControlLabel>Lastname</Form.ControlLabel>
                        <Form.Control name="Lastname" />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Email</Form.ControlLabel>
                        <Form.Control name="Email" type="email" />
                    </Form.Group>
                </Col>
                <Col xs={24} style={{ marginBottom: 20, marginTop: 20 }}>
                    <Form.Group>
                        <Form.ControlLabel>Plan a meeting</Form.ControlLabel>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder="Plan a meeting with me"
                            block
                            ranges={[
                                {
                                    label: "Now",
                                    value: new Date()
                                }
                            ]}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
};

export default PersonForm;
