import React from "react";
import { Button } from "rsuite";

export const IconSelect = ({ selected, icon, text, onClick }) => {
    return (
        <div
            style={{
                padding: 20
            }}
        >
            <Button
                onClick={onClick}
                appearance="default"
                style={{
                    backgroundColor: "#fff",
                    borderRadius: 25,
                    height: 200,
                    ...(selected
                        ? {
                              backgroundColor: "#FFFBFB",
                              border: "3px solid #F3143B"
                          }
                        : {})
                }}
                block
            >
                <img src={icon} alt="React Logo" />
                <h4
                    style={{
                        width: "100%"
                    }}
                >
                    {text}
                </h4>
            </Button>
        </div>
    );
};
