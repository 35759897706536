import React from "react";
import { Button, Col, FlexboxGrid, Form, Grid, Row, Steps } from "rsuite";

import { IconSelect } from "../elements/IconSelect";

const Icons = [
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e7015b5f22d21e5b16_laptop.svg",
        text: "Website"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e8f2025140476db60c_smartphone-1.svg",
        text: "Mobile App"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e7470f1a80207a48fd_bar-chart.svg",
        text: "Marketing"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e711fe8b6a87a1009e_like.svg",
        text: "Visual Identity"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e9470f1aea9b7a492d_shopping-bag.svg",
        text: "Webshop"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e9808eb0efa6d5d614_user-4.svg",
        text: "Conversion"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e72d167b57e5c64104_search.svg",
        text: "SEO / SEA"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e76ab65570b508a04f_pencil.svg",
        text: "Design"
    }
];

const BudgetIcons = [
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e711b32840f973fa0f_money1.svg",
        text: "0 - 2K"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e854f39c059b57ce68_money2.svg",
        text: "2k - 10k"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e73bebc551a8a28594_money3.svg",
        text: "10k - 20k"
    },
    {
        icon: "https://uploads-ssl.webflow.com/6091482b1e84506970eeeff5/611421e7470f1af8017a490f_money4.svg",
        text: "+ 20k"
    }
];

const BusinessForm = () => {
    const [step, setStep] = React.useState(0);
    const [selected, setSelected] = React.useState(0);
    const [selectedPrice, setSelectedPrice] = React.useState(0);
    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
    };

    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);

    return (
        <div>
            <Steps current={step} style={{ marginBottom: 60 }}>
                <Steps.Item title="Service" />
                <Steps.Item title="Project details" />
                <Steps.Item title="Budget" />
                <Steps.Item title="Contact Info" />
            </Steps>
            <div
                style={
                    step !== 0
                        ? {
                              display: "none"
                          }
                        : {}
                }
            >
                <FlexboxGrid justify="space-around">
                    {Icons.map(({ icon, text }, key) => (
                        <FlexboxGrid.Item as={Col} colspan={24} xs={12} md={6} key={key}>
                            <IconSelect
                                onClick={() => setSelected(key)}
                                icon={icon}
                                selected={selected === key}
                                text={text}
                            />
                        </FlexboxGrid.Item>
                    ))}
                </FlexboxGrid>
            </div>
            <Form
                fluid
                style={
                    step !== 1
                        ? {
                              display: "none"
                          }
                        : {}
                }
            >
                <Form.Group>
                    <Form.ControlLabel>Tell us something about your project.</Form.ControlLabel>
                    <Form.Control rows={10} style={{ width: "100%" }} name="textarea" componentClass="textarea" />
                </Form.Group>
            </Form>
            <div
                style={
                    step !== 2
                        ? {
                              display: "none"
                          }
                        : {}
                }
            >
                <FlexboxGrid justify="space-around">
                    {BudgetIcons.map(({ icon, text }, key) => (
                        <FlexboxGrid.Item componentClass={Col} colspan={24} xs={12} md={6} key={key}>
                            <IconSelect
                                onClick={() => setSelectedPrice(key)}
                                icon={icon}
                                selected={selectedPrice === key}
                                text={text}
                            />
                        </FlexboxGrid.Item>
                    ))}
                </FlexboxGrid>
            </div>
            <Grid style={{ display: step === 3 ? "block" : "none", margin: "0 auto", maxWidth: 500 }}>
                <Form fluid>
                    <Row>
                        <Col xs={24} style={{ marginBottom: 20 }}>
                            <Form.Group>
                                <Form.ControlLabel>Company name</Form.ControlLabel>
                                <Form.Control name="Company name" />
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.ControlLabel>Firstname</Form.ControlLabel>
                                <Form.Control name="Firstname" />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Phone</Form.ControlLabel>
                                <Form.Control name="Phone" />
                            </Form.Group>
                        </Col>
                        <Col xs={12} style={{ marginBottom: 20 }}>
                            <Form.Group>
                                <Form.ControlLabel>Lastname</Form.ControlLabel>
                                <Form.Control name="Lastname" />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Email</Form.ControlLabel>
                                <Form.Control name="Email" type="email" />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Grid>
            <FlexboxGrid justify="center" style={{ marginTop: 30 }}>
                <Button
                    onClick={onPrevious}
                    componentClass="button"
                    size={"lg"}
                    style={{ margin: "10px 10px" }}
                    disabled={step === 0}
                    color={"red"}
                >
                    Previous
                </Button>
                {step !== 3 ? (
                    <Button
                        onClick={onNext}
                        size={"lg"}
                        componentClass="button"
                        style={{ margin: "10px 10px" }}
                        disabled={step === 3}
                        color={"red"}
                    >
                        Next
                    </Button>
                ) : (
                    <Button color={"green"} size={"lg"} componentClass="button" style={{ margin: "10px 10px" }}>
                        Submit request
                    </Button>
                )}
            </FlexboxGrid>
        </div>
    );
};

export default BusinessForm;
