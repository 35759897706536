import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import HauserForm from "./form/HauserForm";
import BusinessForm from "./form/BusinessForm";
import PersonForm from "./form/PersonForm";
import "./assets/less/custom.less";

const hauserForm = document.getElementById("hauser-form");
const personForm = document.getElementById("person-form");
const businessForm = document.getElementById("business-form");

let Selector = null;
let Form = null;
if (businessForm) {
    Selector = businessForm;
    Form = "BusinessForm";
}
if (personForm) {
    Selector = personForm;
    Form = "PersonForm";
}
if (hauserForm) {
    Selector = hauserForm;
    Form = "HauserForm";
}

const Forms = {
    BusinessForm: <BusinessForm />,
    HauserForm: <HauserForm />,
    PersonForm: <PersonForm />
};

ReactDOM.render(<React.StrictMode>{Forms[Form]}</React.StrictMode>, Selector);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
